import { Button, createTheme, ThemeProvider } from '@mui/material';
import cn from 'classnames';
import React from 'react';
import { withLayout } from '../Layout/Layout';
import styles from '../styles/modules/404.module.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00454A',
    },
    secondary: {
      main: '#202020',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
    color: '#202020',
  },
});

export function Custom404() {
  return (
    <ThemeProvider theme={theme}>
      <div className={cn(styles.notFoundContainer, 'container')}>
        <h1 className="block font-garamond">404!</h1>
        <h3 className="font-garamond">This page could not be found.</h3>
        <div className="font-libre">
          Your journey has taken an unexpected detour. While we couldn&apos;t find the exact destination you were seeking, there are countless other wonders waiting to be explored. Perhaps this is a sign to wander off the beaten path, discover hidden gems, or embark on a completely new adventure. Remember, the most memorable journeys often begin with a wrong turn. So, pack your bags, let your spirit guide you, and embrace the unexpected. Your next unforgettable adventure with BusinessClass.com awaits. If you&apos;re looking for any community related stuff, try searching the Forum.
        </div>
        <Button
          variant="outlined"
          color="secondary"
          className="mt-2"
          type="button"
          onClick={() => {
            document.location = '/';
          }}
        >
          Click here to go to frontpage.
        </Button>
      </div>
    </ThemeProvider>
  );
}

export default withLayout(Custom404, false);
